import React, { useEffect, useRef, useState } from 'react';
import { Heading, Card, CardBody, TableContainer, TableCaption, Table, Thead, Tr, Th, Tbody, Td, Box, HStack, Button, Text } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { daysData } from '../data/daysData';
import { moonData } from '../data/moonData';

const Main = () => {
    const [hijriDate, setHijriDate] = useState("");
    const [today, setToday] = useState();
    const [todayData, setTodayData] = useState();
    const [isRefreshClicked, setIsRefreshClicked] = useState(false);
    const containerRef = useRef(); // Reference for the scroll container

    useEffect(() => {
        const calculateHijriDate = () => {
          const deviceDate = new Date();
          let hijriYear, hijriMonth, hijriDay;
    
          for (let i = 0; i < moonData.length; i++) {
            const startDate = new Date(moonData[i].startDate);
            const endDate = new Date(
              startDate.getTime() + moonData[i].days * 24 * 60 * 60 * 1000
            );
    
            if (deviceDate >= startDate && deviceDate < endDate) {
              hijriYear = moonData[i].hijriYear;
              hijriMonth = moonData[i].hijriMonth;
              hijriDay = Math.floor(
                (deviceDate - startDate) / (1000 * 60 * 60 * 24)
              ); // add +1 here for non bangladesh date! important!
              break;
            }
          }
    
          if (hijriYear && hijriMonth && hijriDay) {
            const hDate = {
                year : hijriYear,
                month : hijriMonth,
                day : hijriDay
            }
            setHijriDate(hDate);
            setToday(hijriDay);
          } else {
            setHijriDate("Date out of range.");
          }
        };
    
        calculateHijriDate();
      }, [isRefreshClicked]);

    useEffect(() => {
        if (today) {
            setTodayData(daysData[today]);
            const activeButton = document.getElementById(`day-button-${today}`);
            if (activeButton && containerRef.current) {
                // Calculate the offset to center the active button
                const containerWidth = containerRef.current.offsetWidth;
                const buttonOffset = activeButton.offsetLeft - containerWidth / 2 + activeButton.offsetWidth / 2;
                containerRef.current.scrollTo({
                    left: buttonOffset,
                    behavior: 'smooth'
                });
            }
        }
    }, [today]);

    const handleTodayClick = (dayId) => {
        setToday(dayId);
    };

    const hijriMonthsName = [ "Muharram", "Safar", "Rabi al-awwal", "Rabi al-thani", "Jumada al-ula", 
        "jumada al-akhira", "Rajab", "Sha'ban", "Ramadan", "Shawwal", "Dhul-qadah", "Dhul-hijjah"
      ];

    return (
        <Box mt={'10px'} mx={'5px'}>
            <HStack justify='center' mb='10px' bgColor={'yellow.200'}>
                <Button bgColor={'transparent'} size='sm' onClick={() => setIsRefreshClicked(new Date())}>
                    Refresh 🔄
                </Button>
                {hijriDate &&
                    <Text align='center'>
                        Today maybe: {hijriDate.day} {hijriMonthsName[hijriDate.month-1]} {hijriDate.year}
                    </Text>
                }
            </HStack>
            
            <HStack
                ref={containerRef}
                overflowX='auto'
                mb="10px"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    'scrollbarWidth': 'none',
                }}
            >
                <Button
                    onClick={() => handleTodayClick(28)}
                    borderRadius='25px' mx='20px'
                >
                    28
                </Button>
                <Button
                    onClick={() => handleTodayClick(29)}
                    borderRadius='25px' mx='20px'
                >
                    29
                </Button>
                <Button
                    onClick={() => handleTodayClick(30)}
                    borderRadius='25px' mx='20px'
                >
                    30
                </Button>
                {Array.from({ length: 30 }, (_, i) => i + 1).map((day) => (
                    <Button
                        key={day}
                        id={`day-button-${day}`} // Add id for each button
                        onClick={() => handleTodayClick(day)}
                        colorScheme={day === today ? 'green' : 'gray'}
                        borderRadius='25px'
                        mx='20px'
                    >
                        {day}
                    </Button>
                ))}
                <Button
                    onClick={() => handleTodayClick(1)}
                    borderRadius='25px' mx='20px'
                >
                    1
                </Button>
                <Button
                    onClick={() => handleTodayClick(2)}
                    borderRadius='25px' mx='20px'
                >
                    2
                </Button>
                <Button
                    onClick={() => handleTodayClick(3)}
                    borderRadius='25px' mx='20px'
                >
                    3
                </Button>
            </HStack>

            {todayData && (
                <Card background={todayData.bgcol}>
                    <Heading align='center'>{todayData.title}</Heading>
                    <CardBody>
                        <TableContainer>
                            <Table size='sm'>
                                <TableCaption>Estimated required time {todayData.time}</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Id</Th>
                                        <Th>Sura</Th>
                                        <Th>Ayah</Th>
                                        <Th>Open</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {todayData.sura.map((surli) => (
                                        <Tr key={surli.id}>
                                            <Td>{surli.id}</Td>
                                            <Td>{surli.name}</Td>
                                            <Td>{surli.ayah}</Td>
                                            <Td><Link to={`/sura/${surli.id}`}>▶️</Link></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            )}
        </Box>
    );
};

export default Main;
