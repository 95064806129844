import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  Alert,
  AlertIcon,
  useToast,
  Link,
  Text,
  Flex,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../initFirebase/initFirebase'; // Ensure db is exported from your Firebase init file
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

const Signup = () => {
  // State variables for form inputs
  const [name, setName] = useState(''); // New state for name
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  // Confirm Password State
  const [confirmPassword, setConfirmPassword] = useState('');

  // State variables for password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Handling loading state
  const [isLoading, setIsLoading] = useState(false);

  // Error handling
  const [error, setError] = useState('');

  // Chakra UI toast for success messages
  const toast = useToast();

  // React Router's navigate function for redirection
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error state
    setError('');

    // Simple validation
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (name.trim() === '') {
      setError("Name is required.");
      return;
    }

    setIsLoading(true);

    try {
      // Create user with Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Prepare user profile data
      const userProfile = {
        name: name.trim(),
        email: email.trim(),
        createdAt: serverTimestamp(),
      };

      // Create a document in Firestore under 'profile' collection with UID as doc ID
      await setDoc(doc(db, 'profile', user.uid), userProfile);

      // Show success toast
      toast({
        title: 'Account created.',
        description: "You've successfully created an account.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Redirect to dashboard or login page
      navigate('/profile'); // Replace with your desired route
    } catch (err) {
      console.error("Error creating user:", err);
      setError(err.message);
    }

    setIsLoading(false);
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      mt={8}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading mb={6} textAlign="center">
        Sign Up
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          {/* Name Field */}
          <FormControl id="name" isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          {/* Email Field */}
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          {/* Password Field */}
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement>
                {showPassword ? 
                  <Text onClick={() => setShowPassword(!showPassword)}>👁️</Text> 
                : 
                  <Text onClick={() => setShowPassword(!showPassword)}>👁️</Text> 
                }
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Confirm Password Field */}
          <FormControl id="confirmPassword" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <InputRightElement>
                {showConfirmPassword ? 
                  <Text onClick={() => setShowConfirmPassword(!showConfirmPassword)}>👁️</Text> 
                : 
                  <Text onClick={() => setShowConfirmPassword(!showConfirmPassword)}>👁️</Text> 
                }
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Submit Button */}
          <Button
            colorScheme="teal"
            type="submit"
            width="full"
            isLoading={isLoading}
          >
            Sign Up
          </Button>
        </VStack>
      </form>

      {/* Additional Links */}
      <Flex mt={4} justifyContent="space-between" alignItems="center">
        <Text fontSize="sm">
          Already have an account?{' '}
          <Link as={RouterLink} to="/login" color="teal.500">
            Login
          </Link>
        </Text>
        <Text fontSize="sm">
          <Link as={RouterLink} to="/reset-password" color="teal.500">
            Forgot Password?
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default Signup;
