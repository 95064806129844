// SinglePost.jsx
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  Input,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../../initFirebase/initFirebase';

const SinglePost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null); // Store post data
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [commentText, setCommentText] = useState(''); // New comment text
  const [isSubmitting, setIsSubmitting] = useState(false); // Submitting state

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const postRef = doc(db, 'posts', postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const data = postSnap.data();
          setPost(data);
        } else {
          throw new Error('Post not found');
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [postId, isSubmitting]); // Dependency on isSubmitting ensures comments refresh after submission

  // Handle adding a new comment
  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const postRef = doc(db, 'posts', postId);

      // Generate a unique key for the new comment
      const newCommentKey = `comment_${Date.now()}`;

      const newComment = {
        fieldType: 'comment',
        text: commentText.trim(),
        createdAt: serverTimestamp(),
        // Optionally, add author information here, e.g., authorId, authorName
      };

      // Prepare the update data
      const updateData = {
        [newCommentKey]: newComment,
      };

      await updateDoc(postRef, updateData);

      setCommentText('');
      // No need to manually update comments; the useEffect will re-fetch them
    } catch (err) {
      console.error('Error adding comment:', err);
      setError('Failed to add comment');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleString(); // Customize the format as needed
  };

  // Extract post content and comments
  const getPostContent = () => {
    if (!post) return null;
    return post.post; // Assuming 'post' field contains the post content
  };

  const getComments = () => {
    if (!post) return [];

    // Iterate over the post fields and collect those with fieldType 'comment'
    const comments = Object.entries(post)
      .filter(([key, value]) => key.startsWith('comment_') && value.fieldType === 'comment')
      .map(([key, value]) => ({
        id: key,
        ...value,
      }))
      // Sort comments by createdAt
      .sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return a.createdAt.seconds - b.createdAt.seconds;
        }
        return 0;
      });

    return comments;
  };

  return (
    <Box px="15px" py="20px" maxW="800px" mx="auto">
      {isLoading ? (
        <Spinner size="xl" />
      ) : error ? (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <>
          {post ? (
            <VStack align="start" spacing={6}>
              {/* Post Content */}
              <Box w="100%" bg="gray.100" p={4} borderRadius="md">
                <Text fontSize="lg">{getPostContent().text}</Text>
                {getPostContent().createdAt && (
                  <Text fontSize="sm" color="gray.500" mt={1}>
                    {formatTimestamp(getPostContent().createdAt)}
                  </Text>
                )}
              </Box>

              {/* Comments Section */}
              <Box w="100%" mt={6}>
                <Text fontSize="xl" mb={4}>
                  Comments
                </Text>

                {/* Display comments */}
                {getComments().length > 0 ? (
                  <VStack align="start" spacing={4}>
                    {getComments().map((comment) => (
                      <Box
                        key={comment.id}
                        w="100%"
                        bg="gray.50"
                        p={3}
                        borderRadius="md"
                        boxShadow="sm"
                      >
                        <Text>{comment.text}</Text>
                        {comment.createdAt && (
                          <Text fontSize="sm" color="gray.500" mt={1}>
                            {formatTimestamp(comment.createdAt)}
                          </Text>
                        )}
                      </Box>
                    ))}
                  </VStack>
                ) : (
                  <Text>No comments yet. Be the first to comment!</Text>
                )}

                {/* Add Comment Form */}
                <Box mt={6}>
                  <form onSubmit={handleAddComment}>
                    <FormControl id="comment" isRequired>
                      <FormLabel>Add a Comment</FormLabel>
                      <HStack>
                        <Input
                          type="text"
                          placeholder="Write your comment..."
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                        />
                        <Button
                          type="submit"
                          colorScheme="teal"
                          isLoading={isSubmitting}
                        >
                          Submit
                        </Button>
                      </HStack>
                    </FormControl>
                  </form>
                </Box>
              </Box>
            </VStack>
          ) : (
            <Alert status="warning">
              <AlertIcon />
              Post content is missing.
            </Alert>
          )}
        </>
      )}
    </Box>
  );
};

export default SinglePost;
