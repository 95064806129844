import { Box } from '@chakra-ui/react'
import React from 'react'
import Markdown from 'react-markdown';

const paragraph = `

The Qur’ān stands apart from all revealed scriptures through its unique designation as “Recitation.” This name emphasizes its primary purpose: to be continuously recited. The Divine command, "Recite," was the first revealed word, establishing recitation as the foundation for understanding its wisdom. Studying the Qur’ān is intertwined with its recitation, which purifies the heart, granting access to its deeper knowledge. Only those with pure hearts, free of oppression and rooted in truth, can truly comprehend its divine wisdom.

The Qur’ān’s structure is divinely ordained, and its division into Sūrahs (chapters) follows a deliberate design. Unlike human-constructed divisions, such as the 30 Ajza (parts), Allah’s arrangement must be respected. The Qur’ān itself warns against arbitrary segmentation, describing such actions as sinful. By emphasizing this, I underscore the necessity of preserving the original divisions of the Qur’ān to maintain its sanctity.

### The Order and Length of Sūrahs

The Qur’ān begins with long Sūrahs and ends with shorter ones. This progression—from the extensive verses of Sūrah al-Baqarah to the concise chapters near the end—is not coincidental. It serves multiple purposes: to facilitate a journey through its themes and to reflect the human experience of time and comprehension. The placement of Sūrah al-Fātiḥah as an opening chapter, distinct from the rest of the Qur’ān, signifies its role as a spiritual key. Before diving into the Qur’ān’s depths, one must unlock its doors with this essential chapter.

The longest chapter, Sūrah al-Baqarah, positioned at the beginning, serves as a test for believers. It demands patience and commitment, qualities integral to maintaining the sanctity of the Qur’ān’s divisions. Breaking Sūrahs into fragments for convenience, as seen in the commonly adopted Ajza system, disregards the divine order and invites spiritual consequences.

### The Moon’s Role in Recitation

Time in Islam is deeply linked to the lunar calendar, aligning human practices with natural cosmic rhythms. The moon, a divine marker of time, is essential to the methodology of Qur’ānic recitation. Just as the lunar month governs the observance of Ramadan, it also frames the recitation of the Qur’ān. The Angel Gabriel (alaihissalam) recited the entire Qur’ān to Prophet Muhammad (sallallahu alaihi wasallam) every Ramadan, establishing a precedent for Muslims to complete the Qur’ān within one lunar month.

This lunar alignment enables believers to connect not only with the sacred text but also with the natural flow of time, ascending from the worldly to the timeless. By synchronizing recitation with the moon’s phases, the believer embarks on a spiritual journey that mirrors the cosmic order, drawing closer to divine realities.

### A Methodology for Recitation

The Qur’ān must be recited in its entirety at least once every month. This monthly cycle aligns with the prophetic tradition, emphasizing discipline and devotion. During Ramadan, the significance of this recitation intensifies, as it becomes an act of divine synchronization with Gabriel’s annual recitation.

The Qur’ān’s division into Sūrahs reflects Allah’s wisdom, and each chapter must be recited in its entirety, respecting its divine boundaries. Arbitrary divisions, such as splitting Sūrahs into smaller parts for daily recitation, undermine this methodology. For instance, Sūrah al-Baqarah, the longest chapter, must be completed in one sitting on the first day of the month, without fragmentation. This approach extends to all chapters, ensuring a holistic engagement with the text.

### Key Lessons from the Lunar Month

The lunar month itself is a profound reflection of time. Its phases—waxing, full moon, and waning—serve as spiritual milestones. The first week represents the foundational stages of creation, mirroring the six days during which Allah created the heavens and the earth. On the seventh day, the Sabbath tradition reminds us of the divine order and our place within it.

At the midpoint of the month, the full moon symbolizes enlightenment and the peak of spiritual illumination. The recitation of Sūrah al-Nūr (The Light) on the fifteenth day underscores this divine light. As the month progresses, the recitation cycle mirrors the moon’s descent, guiding the believer through a gradual return to earthly realities while maintaining a connection to the divine.

### The Dangers of Arbitrary Division

The prevailing practice of dividing the Qur’ān into 30 Ajza represents a grave departure from its divine structure. This man-made system arbitrarily fragments chapters, disregarding their inherent coherence and spiritual significance. For example, splitting Sūrah al-Baqarah into multiple parts compromises its intended impact and disrespects the divine arrangement.

Such divisions, likened to “chopping” the Qur’ān, have become widespread, yet they deviate from Allah’s command to recite it as revealed. This deviation not only diminishes the spiritual essence of the recitation but also violates the sanctity of the Qur’ān’s structure. The Qur’ān explicitly warns against this practice, holding those who arbitrarily divide its chapters accountable on the Day of Judgment.

### Divine Intent in Qur’ānic Recitation

The Qur’ān is a living guide, and its recitation should reflect its divine purpose. By adhering to the divinely ordained divisions, believers maintain the integrity of the text and align their practices with prophetic traditions. This discipline fosters a deeper connection to the Qur’ān, enabling its transformative power to purify the heart and illuminate the soul.

The divine methodology of monthly recitation, synchronized with the lunar calendar, offers a pathway to spiritual growth. It calls for a harmonious relationship with time, aligning human actions with the rhythms of creation. Through this alignment, believers transcend worldly constraints, entering a state of timelessness that brings them closer to Allah.

### Conclusion

The Qur’ān’s relationship with the moon highlights a profound connection between divine revelation, time, and cosmic order. By embracing the divine methodology of recitation, believers honor the sanctity of the Qur’ān and unlock its transformative power. This journey requires discipline, respect for the text’s structure, and an unwavering commitment to truth.

In adhering to these principles, the believer not only fulfills a sacred duty but also embarks on a spiritual ascent. This ascent, guided by the light of the Qur’ān and the rhythms of the moon, leads to a deeper understanding of divine realities and the ultimate taste of truth.

`

const Learn = () => {
  const markdownStyles = {
    p: {
      lineHeight: '200%',
    },
    li: {
      textAlign: '-webkit-match-parent',
    },
    ul: {
      paddingInlineStart: '20px',
    },
    ol: {
      listStyleType: 'decimal',
      paddingInlineStart: '40px',
    },
    h1: {
      fontSize: '2em',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5em',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.17em',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1em',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '0.83em',
      fontWeight: 'bold',
    },
    a: {
      color: 'blue.500',
    },
    pre: {
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
      padding: '10px',
    },
    strong: {
      fontWeight: 'bold',
    },
    em: {
      fontStyle: 'italic',
    },
    blockquote: {
      borderLeft: '5px solid #ccc',
      margin: '1.5em 10px',
      padding: '0.5em 10px 0.5em 10px',
    },
    code: {
      fontFamily: 'monospace',
      backgroundColor: '#f0f0f0',
      padding: '2px 4px',
      borderRadius: '4px',
    },
  };

  return (
    <Box sx={markdownStyles} px='10px' m="10px" shadow='md' borderRadius='5px' bgColor='green.50'>
      <Markdown>
        {paragraph}
      </Markdown>
    </Box>
  )
}

export default Learn