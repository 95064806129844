import {
  Box,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  Image,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast, // 1. Import useToast
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  TbNotebook,
  TbFilePencil,
  TbUserCircle,
  TbHeadphones,
  TbMoonStars,
} from "react-icons/tb";
import { PiSignInBold } from "react-icons/pi";
import { TiArrowSortedDown } from "react-icons/ti";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../initFirebase/initFirebase";

const Header = () => {
  const { currentUser } = useContext(AuthContext);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast(); // 2. Initialize the toast

  const handleLogout = async () => { // Make handleLogout async
    try {
      await signOut(auth);
      toast({
        title: "Signed out successfully.",
        description: "You have been logged out.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right", // You can adjust the position as needed
      });
    } catch (error) {
      console.error("Error signing out: ", error);
      toast({
        title: "Error signing out.",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const NavItem = ({ icon, to, color, children, unreadCount }) => (
    <Box mx={2} position="relative">
      <Link to={to} className="nav-link">
        <Flex align="center">
          <Icon as={icon} boxSize={7} color={color} />
          {children ? (
            <Text ml={1} color="black">
              {children}
            </Text>
          ) : null}
        </Flex>
      </Link>
      {unreadCount > 0 && (
        <Box
          position="absolute"
          top="-5px"
          right="-5px"
          bg="red"
          borderRadius="full"
          w="15px"
          h="15px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="8px"
          color="white"
          fontWeight="bold"
          boxShadow="0px 0px 2px rgba(0,0,0,0.5)"
        >
          {unreadCount}
        </Box>
      )}
    </Box>
  );

  const RightMenu = () => (
    <Menu isLazy>
      <MenuButton>
        <HStack style={{ alignItems: "center" }} ml="10px">
          <TbUserCircle size="30px" />
          <TiArrowSortedDown size="15px" style={{ marginLeft: "-10px" }} />
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to="/profile">
          <Icon as={TbUserCircle} boxSize={5} mr={2} />
          Profile
        </MenuItem>
        <MenuItem as={Link} to="/contact">
          <Icon as={TbHeadphones} boxSize={5} mr={2} />
          Contact
        </MenuItem>
        {currentUser ? (
          <MenuItem onClick={handleLogout} justifyContent="center">
            Logout
          </MenuItem>
        ) : (
          <MenuItem as={Link} to="/login">
            <Icon as={PiSignInBold} boxSize={5} mr={2} />
            Login
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );

  return (
    <Box
      as="header"
      w="100%"
      pos="sticky"
      top={0}
      bg="rgba(255, 182, 193, 0.6)"
      backdropFilter="blur(20px)"
      boxShadow="md"
      zIndex="999"
    >
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        p={3}
        maxW="1200px"
        mx="auto"
      >
        <Link to="/">
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/werecite-com.appspot.com/o/assets%2Fimages%2Fwerecite-logo.png?alt=media&token=5cc526f3-9d35-4192-a6d7-676dac31f822"
            h="30px"
            alt="kobul"
          />
        </Link>

        <Box display={{ base: "flex" }} alignItems="center" justify="end">
          <NavItem icon={TbMoonStars} to="/">
            {isMobile ? null : "Home"}
          </NavItem>
          <NavItem icon={TbFilePencil} to="/post">
            {isMobile ? null : "Post"}
          </NavItem>
          <NavItem icon={TbNotebook} to="/learn">
            {isMobile ? null : "Learn"}
          </NavItem>
          <RightMenu />
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
