import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  Alert,
  AlertIcon,
  useToast,
  Link,
  Text,
  Flex,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom'; // Import useNavigate
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../initFirebase/initFirebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // For handling loading state
  const [isLoading, setIsLoading] = useState(false);

  // For error handling
  const [error, setError] = useState('');

  // Chakra UI toast for success messages
  const toast = useToast();

  // Initialize navigate
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast({
        title: 'Login successful.',
        description: "You've successfully logged in.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Use navigate instead of Navigate component
      navigate('/profile');
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      mt={8}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading mb={6} textAlign="center">
        Login
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>

          <Button
            colorScheme="teal"
            type="submit"
            width="full"
            isLoading={isLoading}
          >
            Login
          </Button>
        </VStack>
      </form>

      <Flex mt={4} justifyContent="space-between" alignItems="center">
        <Text fontSize="sm">
          Don't have an account?{' '}
          <Link as={RouterLink} to="/signup" color="teal.500">
            Sign Up
          </Link>
        </Text>
        <Text fontSize="sm">
          <Link as={RouterLink} to="/reset-password" color="teal.500">
            Forgot Password?
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default Login;
