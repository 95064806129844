import React from 'react'
import { ChakraProvider } from "@chakra-ui/react"
import Header from "./component/Header"
import Main from './component/Main'
import { Route, Routes } from 'react-router-dom'
import Sura from './component/Sura'
import Learn from './component/Learn/Learn'
import Post from './component/Post/Post'
import Login from './component/User/Login'
import ResetPassword from './component/User/ResetPassword'
import Signup from './component/User/Signup'
import Profile from './component/User/Profile'
import SinglePost from './component/Post/SinglePost'

const App = () => {
  return (
    <ChakraProvider>
      <Header />
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/sura/:suraId' element={<Sura/>} />
        <Route path='/learn' element={<Learn />} />
        <Route path='/post' element={<Post/>} />
        <Route path='/post/:postId' element={<SinglePost />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='reset-password' element={<ResetPassword />} />
      </Routes>
    </ChakraProvider>
  )
}

export default App