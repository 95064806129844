import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { Box } from '@chakra-ui/react'
import Login from './Login'

const Profile = () => {
  const {currentUser} =useContext(AuthContext)
  return (
    <Box>
      { currentUser ?
        <p>hi: {currentUser.email}</p>
        :
        <Login/>
      }
    </Box>
  )
}

export default Profile