export const moonData = [
  { hijriYear: 1445, hijriMonth: 7, startDate: "13-Jan-2024", days: 29 },
  { hijriYear: 1445, hijriMonth: 8, startDate: "11-Feb-2024", days: 30 },
  { hijriYear: 1445, hijriMonth: 9, startDate: "12-Mar-2024", days: 29 },
  { hijriYear: 1445, hijriMonth: 10, startDate: "10-Apr-2024", days: 29 },
  { hijriYear: 1445, hijriMonth: 11, startDate: "9-May-2024", days: 30 },
  { hijriYear: 1445, hijriMonth: 12, startDate: "8-Jun-2024", days: 29 },
  { hijriYear: 1446, hijriMonth: 1, startDate: "7-Jul-2024", days: 30 },
  { hijriYear: 1446, hijriMonth: 2, startDate: "6-Aug-2024", days: 29 },
  { hijriYear: 1446, hijriMonth: 3, startDate: "4-Sep-2024", days: 30 },
  { hijriYear: 1446, hijriMonth: 4, startDate: "4-Oct-2024", days: 30 },
  { hijriYear: 1446, hijriMonth: 5, startDate: "3-Nov-2024", days: 30 },
  { hijriYear: 1446, hijriMonth: 6, startDate: "3-Dec-2024", days: 29 },
  { hijriYear: 1446, hijriMonth: 7, startDate: "1-Jan-2025", days: 30 },
  { hijriYear: 1446, hijriMonth: 8, startDate: "31-Jan-2025", days: 29 },
  { hijriYear: 1446, hijriMonth: 9, startDate: "1-Mar-2025", days: 30 },
  { hijriYear: 1446, hijriMonth: 10, startDate: "31-Mar-2025", days: 29 },
  { hijriYear: 1446, hijriMonth: 11, startDate: "29-Apr-2025", days: 29 },
  { hijriYear: 1446, hijriMonth: 12, startDate: "28-May-2025", days: 30 },
  { hijriYear: 1447, hijriMonth: 1, startDate: "27-Jun-2025", days: 29 },
  { hijriYear: 1447, hijriMonth: 2, startDate: "26-Jul-2025", days: 29 },
  { hijriYear: 1447, hijriMonth: 3, startDate: "24-Aug-2025", days: 30 },
  { hijriYear: 1447, hijriMonth: 4, startDate: "23-Sep-2025", days: 30 },
  { hijriYear: 1447, hijriMonth: 5, startDate: "23-Oct-2025", days: 30 },
  { hijriYear: 1447, hijriMonth: 6, startDate: "22-Nov-2025", days: 29 },
  { hijriYear: 1447, hijriMonth: 7, startDate: "21-Dec-2025", days: 30 },
  { hijriYear: 1447, hijriMonth: 8, startDate: "20-Jan-2026", days: 30 },
  { hijriYear: 1447, hijriMonth: 9, startDate: "19-Feb-2026", days: 29 },
  { hijriYear: 1447, hijriMonth: 10, startDate: "20-Mar-2026", days: 30 },
  { hijriYear: 1447, hijriMonth: 11, startDate: "19-Apr-2026", days: 29 },
  { hijriYear: 1447, hijriMonth: 12, startDate: "18-May-2026", days: 29 },
  { hijriYear: 1448, hijriMonth: 1, startDate: "16-Jun-2026", days: 30 },
  { hijriYear: 1448, hijriMonth: 2, startDate: "16-Jul-2026", days: 29 },
  { hijriYear: 1448, hijriMonth: 3, startDate: "14-Aug-2026", days: 29 },
  { hijriYear: 1448, hijriMonth: 4, startDate: "12-Sep-2026", days: 30 },
  { hijriYear: 1448, hijriMonth: 5, startDate: "12-Oct-2026", days: 30 },
  { hijriYear: 1448, hijriMonth: 6, startDate: "11-Nov-2026", days: 29 },
  { hijriYear: 1448, hijriMonth: 7, startDate: "10-Dec-2026", days: 30 },
  { hijriYear: 1448, hijriMonth: 8, startDate: "9-Jan-2027", days: 30 },
  { hijriYear: 1448, hijriMonth: 9, startDate: "8-Feb-2027", days: 30 },
  { hijriYear: 1448, hijriMonth: 10, startDate: "10-Mar-2027", days: 29 },
  { hijriYear: 1448, hijriMonth: 11, startDate: "8-Apr-2027", days: 30 },
  { hijriYear: 1448, hijriMonth: 12, startDate: "8-May-2027", days: 29 },
  { hijriYear: 1449, hijriMonth: 1, startDate: "6-Jun-2027", days: 29 },
  { hijriYear: 1449, hijriMonth: 2, startDate: "5-Jul-2027", days: 30 },
  { hijriYear: 1449, hijriMonth: 3, startDate: "4-Aug-2027", days: 29 },
  { hijriYear: 1449, hijriMonth: 4, startDate: "2-Sep-2027", days: 29 },
  { hijriYear: 1449, hijriMonth: 5, startDate: "1-Oct-2027", days: 30 },
  { hijriYear: 1449, hijriMonth: 6, startDate: "31-Oct-2027", days: 29 },
  { hijriYear: 1449, hijriMonth: 7, startDate: "29-Nov-2027", days: 30 },
  { hijriYear: 1449, hijriMonth: 8, startDate: "29-Dec-2027", days: 30 },
];
